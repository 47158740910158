import React from "react";
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import Splash from './../components/Splash'
import SEO from "../components/seo"

const IndexPage = ({
  data: {
    site
  }
}) => {
  const siteTitle = site.siteMetadata.title
  const description = site.siteMetadata.description
  return (
    <Layout >
      <SEO 
        title="Homepage"
        metatitle={siteTitle}
        description={description}
        keywords={[`Yato`, `interior design`, `events management`, `communications`, `kigali`, `rwanda`, `PR`, `PR company`, `architecture`, `fittings`, `furnitures`, `high-end`]}
      />
      <Splash />
    </Layout>    
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
