import React from 'react'
import { Keyframes, animated } from 'react-spring/renderprops'
//import { TimingAnimation, Easing } from '../../../src/addons'

import Icon from "./../assets/images/download.png"

const Container = Keyframes.Spring(async next => {
  while (true) {
    await next({
      from: { radians: 0 },
      to: { radians: 2 * Math.PI },
    })
  }
})

export default class TimingExample extends React.PureComponent {
  state = { items: ['item1'] }

  render() {
    const Content = ({ radians, color }) =>
      this.state.items.map((_, i) => (
        <animated.div
          key={i}
          style={{
            willChange: 'transform',
            transform: radians.interpolate(
              r =>
                `translate3d(0, ${10 *
                  Math.sin(r + (i * 2 * Math.PI) / 5)}px, 0)`
            ),
          }}>
          <div>
            <span data-aos="fade-zoom-in" data-aos-delay="3000" className="indicator-arrow text-white indicator-arrow-one mb-4" style={{opacity: 9, transform: 'matrix(0.7071, 0.7071, -0.7071, 0.7071, -5, -5)'}} />
          </div>
        </animated.div>
      ))
    const { items } = this.state

    return (
      <div>
        <Container
          reset
          native
          keys={items}
          //impl={TimingAnimation}
          config={{ duration: 2000 /*, easing: Easing.linear*/ }}>
          {Content}
        </Container>
      </div>
    )
  }
}