import React from 'react'
import { Link } from "gatsby"
import Typing from 'react-typing-animation';
import Zoom from 'react-reveal/Zoom'
import Icon from "./demo-1"
import Fade from 'react-reveal/Fade'
import Ishango from './../images/ishango-light.svg'
import Logo from "./../assets/images/OI_logo_WhiteAsset.png"

function Splash() {
  return (
    <div>
      <section>
        <div className="container-fluid">
          <div data-aos="zoom-in" data-aos-duration="1500">
            <Link to="/home/">
            <div className="bg-cover min-vh-80" style={{background: `url(${Ishango}) center center`, backgroundRepeat: 'no-repeat', backgroundSize: '300px'}}>
              <div className="row align-items-center justify-content-center text-center min-vh-100">
                <div className="col-12 col-md text-white">
                  <Link to="/home/">
                  <Typing hideCursor={true} speed={60} >
                    <div className="mt-n8">

                      <Typing.Delay ms={1000} />
                      <span className="display-4 text-light font-weight-bolder">Stretch your Boundaries</span>
                      <Typing.Reset count={1} delay={500} />

                      <div className="" data-aos="zoom-in" data-aos-duration="1500"><img src={Logo} className="logo-splash" alt="Organic" /></div>
                    </div>
                  </Typing>
                  </Link>
                </div>
              </div>
            </div>

          </Link>
          </div>
          <div>
            <div>
              {/* <Link to="/home/" className="indicator pb-8 indicator-down">
                <Icon />
              </Link> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Splash
